import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import PageSection from "../PageSection";
import Title from "../Title"
import styles from "../../css/blog.module.css";
import BlogItem from "../singles/BlogItem";

const Blog = () => {
    const query = useStaticQuery(pageQuery);

    function sliceArray(array) {
        if (array.length > 2) {
            if (typeof window !== 'undefined' && window.outerWidth < 1018) {
                return array.slice(0,2)
            } else {
                return array.slice(0,3)
            }
        }
        return array;
    }

    return (
        <PageSection background={"#1A1A1A"}>
            {query.page.edges.map(({node}) => {
                return (
                    <div key={node.id}>
                        <Title title={node.frontmatter.heading} subtitle={node.frontmatter.subHeading} sectionNumber={4}/>
                        <div className={styles.blogs}>
                            {/* TODO: query sort date */}
                            {sliceArray(query.blog.edges.sort((a, b) => (a.node.frontmatter.date < b.node.frontmatter.date) ? 1 : -1)).map((blogItem) => {
                                return (<BlogItem blogItem={blogItem.node.frontmatter}/>)
                            })}
                        </div>
                        <Link fade to={"/blog"}>
                            <button className={'btn-primary'}>{node.frontmatter.ctaText}</button>
                        </Link>
                    </div>
                )
            })}
        </PageSection>
    )
};

export const pageQuery = graphql`
    query {
        page: allMarkdownRemark(filter: {fileAbsolutePath: {regex : "\\/sections/blog/"} }) {
            edges {
                node {
                    id
                    frontmatter {
                        heading
                        subHeading
                        ctaText
                    }
                }
            }
        }
        blog: allMarkdownRemark(limit: 1000 filter: {frontmatter: {templateKey: {eq: "blog-template"}}}) {
            edges {
                node {
                    frontmatter {
                        slug
                        title
                        url
                        image
                        date
                        readingTime
                        tags 
                    }
                    frontmatter {
                        templateKey
                    }
                    html
                }
            }
        }
    }
`;

export default Blog