import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import styles from "../../css/projects.module.css"
import PageSection from "../PageSection";
import Title from "../Title"
import ProjectItem from "../singles/ProjectItem";

const Projects = () => {
    const query = useStaticQuery(pageQuery);

    function sliceArray(array) {
        if (array.length > 2) {
            if (typeof window !== 'undefined' && window.outerWidth < 1018) {
                return array.slice(array.length - 4, array.length).reverse()
            } else {
                return array.slice(array.length - 4, array.length).reverse()
            }
        }
        return array;
    }

    return (
        <PageSection>
            {query.page.edges.map(({node}) => {
                return (
                    <div key={node.id}>
                        <Title title={node.frontmatter.heading} subtitle={node.frontmatter.subHeading} sectionNumber={3}/>
                        <div className={styles.projects}>
                            {sliceArray(query.projects.edges.sort((a, b) => (a.node.frontmatter.date > b.node.frontmatter.date) ? 1 : -1)).map((project) => {
                                    return (
                                        <ProjectItem projectItem={project.node.frontmatter}/>
                                    )
                                }
                            )}
                        </div>
                        <Link fade to={"/portfolio"}>
                            <button className={'btn-primary'}>{node.frontmatter.ctaText}</button>
                        </Link>
                    </div>
                )
            })}
        </PageSection>
    )
};

export const pageQuery = graphql`
    query {
        page: allMarkdownRemark(filter: {fileAbsolutePath: {regex : "\\/sections/projects/"} }) {
            edges {
                node {
                    id
                    frontmatter {
                        heading
                        subHeading
                        ctaText
                    }
                }
            }
        }
        projects: allMarkdownRemark(limit: 1000 filter: {frontmatter: {templateKey: {eq: "project-template"}}}, sort: { fields: frontmatter___date, order: DESC }) {
            edges {
                node {
                    frontmatter {
                        slug
                        title
                        thumbnail
                        logo
                        productImage
                        description
                        date
                    }
                    frontmatter {
                        templateKey
                    }
                }
            }
        }
    }
`;

export default Projects