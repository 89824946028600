import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import styles from "../../css/services.module.css"
import Title from "../Title"
import PageSection from "../PageSection";

const Services = () => {
    const {allMarkdownRemark: {edges}} = useStaticQuery(pageQuery);
    return (
        <PageSection background={"#1A1A1A"}>
            {edges.map(({node}) => {
                return (
                    <div key={node.id}>
                        <Title title={node.frontmatter.heading} subtitle={node.frontmatter.subHeading} sectionNumber={2}/>
                        <div className={styles.services}>
                            {node.frontmatter.serviceItems.map((serviceItem) => {
                                    return (
                                        <article className={styles.service}>
                                            <h4>{serviceItem.heading}</h4>
                                            <p>{serviceItem.description}</p>
                                        </article>
                                    )
                                }
                            )}
                        </div>
                    </div>
                )
            })}
        </PageSection>
    )
};

export const pageQuery = graphql`
    query {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex : "\\/sections/services/"} }) {
            edges {
                node {
                    id
                    frontmatter {
                        heading
                        subHeading
                        serviceItems {
                            heading
                            description
                        }
                    }
                }
            }
        }
    }
`

export default Services