import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styles from "../../css/header.module.css"
import { motion } from "framer-motion"

const Header = () => {
    const data = useStaticQuery(pageQuery);
    return (
        <div>
            {data.page.edges.map(({ node }) => {
                return (
                    <motion.div
                    initial={{ opacity: 0}}
                    animate={{ opacity: 1}}
                    transition={{ duration: 3 }}
                        >
                        <div key={node.id}>
                            <div className={styles.overlay} />
                            <BackgroundImage fluid={data.backgroundImage.childImageSharp.fluid} className={styles.header}
                                preserveStackingContext={true}>
                                <section className={styles.headerContainer}>
                                    <h1>{node.frontmatter.heading}</h1>
                                    <h2>{node.frontmatter.subHeading}</h2>
                                    <Link fade to={"/#contact"}>
                                        <button className="btn-primary">{node.frontmatter.ctaText}</button>
                                    </Link>
                                </section>
                            </BackgroundImage>
                        </div>
                    </motion.div>
                )
            })}
        </div>
    )
};

export const pageQuery = graphql`
    query {
        page: allMarkdownRemark(filter: {fileAbsolutePath: {regex : "\\/sections/header/"} }) {
            edges {
                node {
                    id
                    frontmatter {
                        logo
                        heroImage
                        heading
                        subHeading
                        ctaText
                    }
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "header.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 4160) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

export default Header