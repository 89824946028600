import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styles from "../../css/about.module.css"
import PageSection from "../PageSection";
import Title from "../Title"

const About = () => {
    const { allMarkdownRemark: { edges } } = useStaticQuery(pageQuery);
    return (
        <PageSection>
            {edges.map(({ node }) => {
                return (
                    <div className={styles.aboutCenter} key={node.id} id={"about"}>
                        <Title title={node.frontmatter.heading} sectionNumber={1} />
                        <div className={styles.aboutContainer}>
                            <div dangerouslySetInnerHTML={{ __html: node.html }} className={`${styles.aboutText} ${"gold-ending"}`}/>
                            <div className={styles.aboutImg}>
                                <div className={styles.imgContainer}>
                                    <img src={node.frontmatter.image} alt="about Caspar Geerlings Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </PageSection>
    )
};

export const pageQuery = graphql`
    query {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex : "\\/sections/about/"} }) {
            edges {
                node {
                    frontmatter {
                        heading
                        image
                    }
                    id
                    html
                }
            }
        }
    }
`;

export default About