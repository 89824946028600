import React from "react"
import Layout from "../components/Layout";
import About from "../components/sections/About";
import Services from "../components/sections/Services";
import Projects from "../components/sections/Projects";
import Blog from "../components/sections/Blog";
// import Testimonials from "../components/sections/Testimonials";
import Contact from "../components/sections/Contact";
import Header from "../components/sections/Header";
import SEO from "../components/SEO"

export default ({}) => (
    <Layout>
            <SEO title="Home"/>
        <Header/>
        <About/>
        <Services/>
        <Projects/>
        <Blog/>
        {/* <Testimonials/> */} 
        <Contact/>
    </Layout>
)