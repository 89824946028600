import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import PageSection from "../PageSection";
import styles from "../../css/contact.module.css"
import Title from "../Title"

const Contact = () => {
    const { allMarkdownRemark: { edges } } = useStaticQuery(pageQuery);
    return (
        <PageSection>
            {edges.map(({ node }) => {
                return (
                    <div key={node.id} id={"contact"}>
                        <Title title={node.frontmatter.heading} subtitle={node.frontmatter.subHeading} sectionNumber={5} />
                        <form className={styles.form} name="contact" method="POST" data-netlify="true"
                            data-netlify-honeypot="bot-field">
                            <input type="hidden" name="form-name" value="contact" />
                            <label className={styles.smallLabel}>Your name:
                                <input className={styles.input} type="text" name="name" />
                            </label>
                            <label className={styles.smallLabel}>Your email:
                                <input className={styles.input} type="email" name="email" />
                            </label>
                            <label className={styles.fullLabel}>Message:
                                <textarea className={styles.input} name="message" placeholder="Remember, be nice!😉" />
                            </label>
                            <button type="submit" className={'btn-primary'}>{node.frontmatter.ctaText}</button>
                        </form>
                    </div>
                )
            })}
        </PageSection>
    )
};

export const pageQuery = graphql`
    query {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex : "\\/sections/contact/"} }) {
            edges {
                node {
                    id
                    frontmatter {
                        heading
                        subHeading
                        ctaText
                    }
                }
            }
        }
    }
`;

export default Contact