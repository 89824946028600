import React from "react"
import styles from "../../css/projectItem.module.css"
import PropTypes from "prop-types"
import { Link } from "gatsby";

const ProjectItem = ({ projectItem }) => {

    const { slug, title, thumbnail, description, logo, productImage, playStoreUrl, appStoreUrl, sitePreview, date } = projectItem;

    return (
        <div className={styles.projectContainer}>
            <article className={styles.project}>
                <Link to={`/projects/${slug}`}>
                    <div className={styles.imgContainer}>
                        <img src={thumbnail} className={styles.thumbnail}
                            alt={title + " thumbnail"} />
                    </div>
                </Link>
                <div className={styles.projectContent}>
                    <div className={styles.projectTitle}>{title}</div>
                    {description} 
                    <Link to={`/projects/${slug}`}>
                        <div className={styles.projectCTA}>Learn more ➞</div>
                    </Link>
                    </div>
            </article>
        </div>
    )
};

ProjectItem.propTypes = {
    blogItem: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        thumbnail: PropTypes.object.isRequired,
        description: PropTypes.string.isRequired,
        logo: PropTypes.object.isRequired,
        productImage: PropTypes.object,
        playStoreUrl: PropTypes.string,
        appStoreUrl: PropTypes.string,
        sitePreview: PropTypes.string,
        date: PropTypes.string.isRequired
    }),
};

export default ProjectItem